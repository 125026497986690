<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                align="center"
                >
                <v-btn
                v-if="redirectIkast"
                link
                color="red"
                x-large
                height="80px"
                width="30%"
                style="color:white"
                href="https://ikast-brande.kundeportal.genbrugsbetaling.dk">
                Klik her for at købe abonnement
            </v-btn>

            </v-col>
            <v-col
                cols="12"
                lg="2"
                md="3"
                sm="6"
            >
                <v-form
                    ref="loginform"
                    v-model="valid"
                >
                    <v-card
                        tile
                        elevation="5"
                    >
                        <div :style="'height:70px; display:flex; align-items:center; background-color:' + color +';'">
                            <v-img                                
                                style="max-width:150px; margin: auto;"
                                :src="img"
                            />
                        </div>

                        <v-card-title class="py-1">
                            {{ $t('systemsetting-5') }}
                        </v-card-title>
  
                        <v-card-text
                            v-if="initialLoad"
                            class="ma-0 py-0"
                        >
                            <div class="text-center">
                                <v-progress-circular 
                                    indeterminate
                                />
                            </div>
                        </v-card-text>

                        <v-card-text
                            v-else-if="!useCriiptoLogin"
                            class="ma-0 py-0"
                        >
                            <v-text-field
                                v-model="username"
                                :disabled="working"
                                tabindex="1"
                                :rules="globalCheckNotEmpty(username)"
                                :label="$t('myuser/profile-email')"
                                required
                            />
                            <v-text-field
                                v-model="password"
                                :disabled="working"
                                tabindex="2"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="globalCheckNotEmpty(password)"
                                :label="$t('forcepasswordchange-tab1title')"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                required
                                @click:append="showPassword = !showPassword"
                            />

                            <template v-for="code in responseCodes">
                                <v-alert
                                    :key="code"
                                    class="ma-0 mb-1"
                                    dense
                                    :type="responseSuccess ? 'success' : 'error'"
                                >
                                    <template v-if="responseSuccess">
                                        {{ $t('success-' + code) }}
                                    </template>
                                    <template v-else>
                                        {{ $t('error-' + code) }}
                                    </template>
                                </v-alert>
                            </template>
                        </v-card-text>

                        <v-card-text
                            v-else-if="useCriiptoLogin"
                            class="ma-0 py-0"
                        >
                            <mit-id-button />
                        </v-card-text>

                        <v-alert
                            v-if="loginFailed"
                            color="red"
                        >
                            {{ $t('wrongUsernameOrPassword') }}
                        </v-alert>
                        <v-card-actions                            
                            class="d-flex flex-wrap"
                        > 
                            <v-container
                                v-if="!initialLoad"
                            >                                
                                <v-row
                                    v-if="!useCriiptoLogin"
                                    dense
                                >
                                    <v-col>
                                        <v-btn
                                            :disabled="working"
                                            text
                                            tile
                                            x-small
                                            :to="{ name: 'CreateUser'}"
                                        >
                                            {{ $t('createUser') }}
                                        </v-btn>
                                        <v-btn
                                            :disabled="working"
                                            text
                                            tile
                                            x-small
                                            :to="{ name: 'ForgotPassword' }"
                                        >
                                            {{ $t('forgotPassword') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn
                                            :disabled="!valid"
                                            :loading="working"
                                            tabindex="3"
                                            type="submit"
                                            @click="Login"
                                        >
                                            {{ $t('loginPage-login') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mitIdButton from '@/views/pages/CriiptoLoginButton'
import {callApi} from '../../api/apibase'

export default {
    components: {
        mitIdButton
    }, 
    data() {
        return {
            initialLoad: true,
            working: false,

            valid: false,
            username: null,
            password: null,
            showPassword: false,
            loginFailed: false,
            //Errors
            responseSuccess: false,
            responseCodes: []
        }
    },
    computed: {
        locale() {
            return process.env.VUE_APP_I18N_LOCALE
        },
        useCriiptoLogin(){
            return process.env.VUE_APP_USE_CRIIPTO_LOGIN === 'true'
        }, 
        color(){
            return process.env.VUE_APP_COMPANY_COLOR
        },
        img(){
            return process.env.VUE_APP_COMPANY_LOGO
        },
        redirectIkast(){
            return process.env.VUE_APP_REDIRECT_IKAST === 'true'
        },
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber',
            getSelectedPrivateCustomerName: 'getSelectedPrivateCustomerName' })          
    },
    created() { 
        //Remove any old errors
        localStorage.removeItem('error')
        
        //Kind of a hack to wake up the api
        callApi('GET','/',null,)

        //If a refresh token is present
        //Tjek that it is valid and if yes - redirect the user to welcome page
        if(localStorage.getItem('refreshtoken') !== null){
            this.RefreshTokenInterval({
                refreshToken: localStorage.getItem('refreshtoken'),
                ignoreRedirect: true
            })      
                .then(response => {
                    if(!response){
                        this.initialLoad = false
                    }
                    else if(response.status === 200){
                        if(this.getSelectedCvrNumber != null && this.locale === 'da'){
                            setTimeout(() => {
                                this.$router.push({ name: 'PNumberSelector' })
                            }, 500)
                        }else if(this.getSelectedCvrNumber != null && this.locale === 'se'){
                            setTimeout(() => {
                                this.setSelectedPNumber(this.getSelectedCvrNumber)
                                this.$router.push({ name: 'Welcome' })
                            }, 500)
                        }else{
                            this.initialLoad = false
                        }
                    }    
                    else {
                        this.initialLoad = false
                    }                    
                })
        }    
        else {
            this.initialLoad = false
        }    
    },    
    methods: {
        Login(e){
            this.working = true

            this.responseSuccess = false
            this.responseCodes = []

            //Important when using 'history' mode in router
            e.preventDefault()
            
            this.LogIn({
                username: this.username,
                password: this.password
            })
                .then(loggedIn => {
                    this.responseSuccess = loggedIn

                    if(loggedIn && this.locale === 'da'){
                        this.$router.push({ name: 'PNumberSelector' })                       
                    }
                    else if(loggedIn && this.locale === 'se'){
                        this.FetchUserCvrNumber().then(result => {
                            this.setSelectedCvrNumber(result.cvrNumber)
                            this.setSelectedPNumber(result.cvrNumber)
                            localStorage.setItem('selectedPNumber', result.cvrNumber)
                            
                            
                            this.FetchUser({pNumber:result.cvrNumber}).then(result => {
                                this.setSelectedPNumberName(result.customer.name)
                                localStorage.setItem('selectedPNumberName', result.customer.name)
                            })
                        })
                        this.$router.push({ name: 'Welcome' })
                    }
                    else {
                        this.loginFailed = true
                    }                                                                
                })
                .finally(() => {
                    this.working = false
                })
        },
        ...mapActions({
            LogIn: 'LogIn',
            RefreshTokenInterval: 'RefreshTokenInterval',
            FetchUserCvrNumber: 'FetchUserCvrNumber',
            FetchUser: 'FetchUser'
            
        }),
        ...mapMutations({
            setSelectedCvrNumber: 'setSelectedCvrNumber',
            setSelectedPNumber: 'setSelectedPNumber',
            setSelectedPNumberName: 'setSelectedPNumberName'
        })
    }
}
</script>
